import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { ExploreFiltersType } from '@/types/filters'
import { getFyItems } from '@/utils/dates'

type StoreType = {
  initiatives: InitiativesListItemType[]
  filterOpen: boolean
  filters: Partial<ExploreFiltersType>
}

const initialState = {
  initiatives: [],
  filterOpen: false,
  filters: {
    fy: getFyItems().reverse()[0]
  }
} as StoreType

export const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setInitiatives(state, action: PayloadAction<InitiativesListItemType[]>) {
      state.initiatives = action.payload
    },
    setFiltersModal(state, action: PayloadAction<boolean>) {
      state.filterOpen = action.payload
    },
    setFilter(state, action: PayloadAction<ExploreFiltersType>) {
      state.filters = action.payload
    },
    reset() {
      return initialState
    }
  }
})
