import React from 'react'

import styles from '../styles/Explore.module.scss'
import Text from '@ingka/text'
import { DynamicTranslations, useTranslation } from '@/components/translations/Translations'
import { useHeaderSettings } from '@/components/header/provider'
import { Table } from '@/pages/Explore/components/Table'
import Button from '@ingka/button'
import FiltersIcon from '@ingka/ssr-icon/paths/filters'
import DownloadIcon from '@ingka/ssr-icon/paths/arrow-down-to-line'
import { Filters } from '@/pages/Explore/components/Filters'
import { store } from '@/storage/store'
import { exploreSlice } from '@/pages/Explore/slices/explore'
import { ExploreProvider } from '@/pages/Explore/providers/Explore'
import { exportData } from '@/pages/Explore/utils/export'
import { ActiveFilters } from '@/pages/Explore/components/ActiveFilters'
import Tooltip from '@ingka/tooltip'

export const ExploreImpl = () => {
  useHeaderSettings(true, false)
  const tooltipText = useTranslation('explore.filters.button_tooltip')

  const handleOpenFilters = () => {
    store.dispatch(exploreSlice.actions.setFiltersModal(true))
  }

  return (
    <div className={styles.Explore}>
      <div className={styles.Head}>
        <Text headingSize="l">
          <DynamicTranslations id="explore.title" />
        </Text>
        <Button
          type="tertiary"
          ssrIcon={FiltersIcon}
          small
          className={styles.FiltersButton}
          iconPosition="trailing"
          onClick={handleOpenFilters}
        >
          <DynamicTranslations id="explore.filters.button" />
        </Button>
      </div>
      <div className={styles.Divider} />
      <div className={styles.Controls}>
        <ActiveFilters />
        <Tooltip tooltipText={tooltipText} position="bottom">
          <Button ssrIcon={DownloadIcon} onClick={exportData} small>
            <DynamicTranslations id="explore.export_button" />
          </Button>
        </Tooltip>
      </div>
      <Table />
      <Filters />
    </div>
  )
}

export const Explore = () => {
  return (
    <ExploreProvider>
      <ExploreImpl />
    </ExploreProvider>
  )
}
